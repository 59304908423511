<template>
  <el-dialog :title="'上传' + this.title + '财报记录'" :visible.sync="dialogVisible" width="30%" center
    :before-close="handleClose">
    <div class="UpList">
      <!-- <div class="UploadCredentials">
        <div class="title">
          <span>财报标题：</span>
        </div>
        <div class="innerText">
          <el-input type="text" :rows="2" placeholder="请输入内容" v-model="remarkText">
          </el-input>
        </div>
      </div> -->
      <div class="remark">
        <div class="title">
          <span>财报文件（必传）：</span>
          <span class="little">*多个文件请压缩后上传</span>
        </div>
        <div class="innerText">
          <el-upload v-show="isFlag" class="upload-demo" accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z"
            :action="uploadUrl" :disabled="loading" name="files" :auto-upload="false" :on-change="handleChange"
            :file-list="fileList" :show-file-list="false">
            <i class="el-icon-download"></i>
          </el-upload>
          <li v-for="(item, index) in fileList" :key="index">
            <span @click="AddFiles(item)"> {{ item.FilesName }}</span>
            <!-- <el-button
              @click="del(index)"
              type="danger"
              icon="el-icon-delete"
              circle
            ></el-button> -->
            <i @click="del(index)" class="el-icon-delete"></i>
          </li>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="Cancel()">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="submit()">
        {{ loading ? "提交中" : "提交" }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  // 文件上传
  import { uploadFile } from "@/api/fileApi";
  import { uploadExport } from "@/api/ChargeUp";
  export default {
    data() {
      return {
        dialogVisible: true,
        // remarkText: "", //财报标题
        fileList: [], //上传文件列表
        uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
        filesUp: [], //将要上传的图片
        ItemId: {}, //上传的ID
        title: "", //用户名,
        isFlag: true, //是否显示上传接口
        loading: false, //上传文件的状态
      };
    },

    mounted() { },

    methods: {
      // 取消弹窗按钮
      Cancel() {
        this.$emit("closeDialog2", false);
      },
      //点击关闭弹窗
      handleClose() {
        this.$emit("closeDialog2", false);
      },

      //文件上传
      async handleChange(file, fileList) {
        // console.log(fileList, "这是fileList");
        this.loading = true;
        let param = new FormData();
        param.append("catalog", "financeAdd");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        if (res.code == 0) {
          this.loading = false;
        }
        console.log(res, "这是res");
        let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
        let item = {
          FilesName: res.data.sourceFileName,
          imgUrl: imgUrl,
        };
        this.filesUp.push({
          FilesName: res.data.sourceFileName,
          imgUrl: res.data.url,
        });
        this.fileList.push(item);
        this.isFlag = false;
      },
      //文件下载
      AddFiles(item) {
        let dom = document.createElement("a");
        dom.style.display = "none";
        dom.href = item.imgUrl;
        dom.setAttribute("download", item.FilesName);
        document.body.appendChild(dom);
        dom.click();
      },
      //文件删除
      del(index) {
        this.fileList.filter((item, ind) => {
          if (index == ind) {
            let newFileList = this.fileList.splice(ind, 1);
            let newFilesUp = this.filesUp.splice(ind, 1);
            return newFileList, newFilesUp;
          }
        });
        this.isFlag = true;
      },
      init(item) {
        console.log(item, "查看");
        this.title = item.userName;
        this.ItemId = {
          userId: item.userId,
          identityId: item.identityId,
          userName: item.userName
        };
      },
      //提交按钮
      async submit() {
        console.log(this.filesUp.length);
        if (this.filesUp.length == "0") {
          this.$message.info("请上传至少一个文件");
        } else {
          let params = {
            // title: this.remarkText,
            files: JSON.stringify(this.filesUp),
            ...this.ItemId,
          };
          // console.log(params,'查看')
          let res = await uploadExport(params);
          if (res.code == "0") {
            this.$message.success(res.msg);
            this.$emit("closeDialog2", false);
          } else {
            this.$message.warning(res.msg);
          }
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .UpList {
    width: 100%;
    height: 300px;

    .UploadCredentials {
      padding: 10px;
      display: flex;
      height: 30%;

      .title {
        width: 30%;
        font-size: 15px;
        font-weight: bold;
      }

      .innerText {
        width: 70%;
      }
    }

    .remark {
      display: flex;
      height: 70%;

      .title {
        width: 35%;
        height: 50px;
        font-size: 15px;
        font-weight: bold;
        display: flex;
        flex-direction: column;

        .little {
          font-size: 10px;
          color: red;
        }
      }

      .innerText {
        width: 65%;
        list-style: none;

        .el-icon-download {
          font-size: 30px;
          font-weight: bold;
        }

        img {
          width: 100%;
          height: 100%;
        }

        li {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
</style>
