<template>
  <div>
    <el-dialog :title="this.title + '历史财报'" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
      <table border="1">
        <thead>
          <tr>
            <th>标题</th>
            <th>文件</th>
            <th>操作人</th>
            <th>上传时间</th>
            <th>操作</th>

          </tr>
        </thead>
        <tbody v-if="getList.length">
          <tr v-for="(item, index) in getList" :key="index">
            <td>{{ item.title }}</td>

            <td class="Line" @click="toLookPDF(item.files)">
              {{ JSON.parse(item.files)[0].FilesName }}
            </td>
            <td>{{ item.createrName }}</td>
            <td>{{ item.createTime ? item.createTime.substr(0, 10) : "" }}</td>
            <td>
              <el-button
                v-if="identityObj.identityTypeCode==19||identityObj.identityTypeCode==6||identityObj.identityTypeCode==16"
                type="success" @click="toLookPDF(item.files)">查看</el-button>
              <el-button v-if="identityObj.identityTypeCode==17" type="danger"
                @click="deleteItem(item.financialReportId)">删除</el-button>

            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4">暂无数据</td>
          </tr>
        </tbody>
      </table>
    </el-dialog>
    <lookPDF v-if="isLook" @closeDialog3="closeDialog3" ref="isLook"></lookPDF>
  </div>


</template>

<script>
  import lookPDF from './LookPdf.vue'
  import { mapGetters } from "vuex";
  import { selectExport, download, deleteReport } from "@/api/ChargeUp";
  export default {
    data() {
      return {
        dialogVisible: true,
        getList: [], //列表
        title: "", //这是名字
        isLook: false, //预览图片
        baseUrl: window.globalUrl.BASE_API,
        userObj: {}
      };
    },
    components: {
      lookPDF
    },
    computed: {
      ...mapGetters(["identityObj"]),
    },
    methods: {
      //关闭预览
      closeDialog3(isLook) {
        this.isLook = isLook;
      },
      //财报预览
      toLookPDF(lookPDF) {
        this.isLook = true,
          this.$nextTick(() => {
            this.$refs.isLook.getPDF(lookPDF);
          });
      },
      // 财报删除
      async deleteItem(id) {
        // console.log(id, '查看删除')
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {

          let res = await deleteReport({ id: id });
          if (res.code == 0) {
            this.$message.success(res.msg);
            let params = {
              userId: this.userObj.userId,
              identityId: this.userObj.identityId,
            }
            let res2 = await selectExport(params);
            this.getList = res2.data
            this.title = this.userObj.userName;
          } else {
            this.$message.error(res.msg);
          }

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });



      },
      // 取消弹窗按钮
      Cancel() {
        this.$emit("closeDialog", false);
      },
      //点击关闭弹窗
      handleClose() {
        this.$emit("closeDialog", false);
      },
      async initId(obj) {
        // console.log(obj, "查看");
        this.userObj = obj
        let params = {
          userId: obj.userId,
          identityId: obj.identityId,
        };
        let res = await selectExport(params);
        this.getList = res.data;
        console.log(this.getList);
        this.title = obj.userName;
      },
      //财报下载
      // async Download(item) {
      // let itemList = JSON.parse(item.files)[0];
      // console.log(itemList, "查看");
      // let res = await download({ ossFilePath: itemList.imgUrl });
      // if (res) {
      //   let blob = res;
      //   const fileName = itemList.FilesName;
      //   let downloadElement = document.createElement("a");
      //   let binaryData = [];
      //   binaryData.push(blob);
      //   // "|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z"
      //   let href = window.URL.createObjectURL(
      //     new Blob(binaryData, {
      //       type: "application/pdf||doc||docx||xls||xlsx||zip||7z",
      //     })
      //   ); //创建下载的链接
      //   downloadElement.href = href;
      //   downloadElement.download = decodeURIComponent(fileName); //解码
      //   document.body.appendChild(downloadElement);
      //   downloadElement.click();
      //   document.body.removeChild(downloadElement);
      //   window.URL.revokeObjectURL(href);
      // }

      // },
    },
  };
</script>

<style lang="less" scoped>
  table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;

    tr {
      td {
        height: 50px;
        text-align: center;
      }

      .Line {
        text-decoration: underline;
        color: #1e1ec6;
      }
    }
  }
</style>